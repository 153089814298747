<template>
  <div style="height: 100%;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>图谱展示</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex;position: relative; height: 100%; padding: 20px">
      <div class="knowledge-list">
        <div
          class="item"
          :class="activeKnowledgeId === item.id ? 'checked' : ''"
          v-for="(item, index) in knowledgeList"
          :key="index"
          @click="getTree(item.id)"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div style="flex: auto;margin-left: 10px; height: 100%;">
        <div ref="chart" class="chart" />
      </div>
      <div class="reset-button" @click="resetTree">重置</div>
    </div>
  </div>
</template>

<script>
import knowledgeApi from '@api/knowledge'

export default {
  name: 'KnowledgeGraphics',
  data() {
    return {
      activeKnowledgeId: '',
      knowledgeList: [],
      chart: null,
      activeKnowLedge: {},
      chartNodes: [],
      chartLinks: [],
      chartOption: {}
    }
  },
  mounted() {
    this.getKnowledgeList()
    window.addEventListener('resize', this.resize)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    getKnowledgeList() {
      knowledgeApi.getKnowledgeRoot().then(res => {
        this.knowledgeList = res.res
          .filter(item => {
            if (item.status === false) {
              return item
            }
          })
          .reverse()
        this.getTree(this.knowledgeList[0].id)
      })
    },
    getTree(id) {
      if (this.activeKnowledgeId === id) {
        return
      }
      this.activeKnowledgeId = id
      knowledgeApi.getKnowledgeChildren(id).then(res => {
        this.activeKnowLedge = res.res
        this.chartNodes = [
          {
            category: this.activeKnowLedge.name,
            name: this.activeKnowLedge.id.toString(),
            knowledgeName: this.activeKnowLedge.name,
            symbolSize: 10
          }
        ]
        this.handleNode(this.activeKnowLedge.children, this.activeKnowLedge)
        this.chartOption = {
          series: [
            {
              name: '知识图谱',
              type: 'graph',
              layout: 'force',
              data: this.chartNodes,
              links: this.chartLinks,
              categories: { name: this.activeKnowLedge.name },
              roam: true,
              symbol: 'circle',
              force: {
                repulsion: 200
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.3)'
              },
              label: {
                show: true,
                position: 'right',
                formatter: function(params) {
                  return params.data.knowledgeName
                },
                color: '#000'
              },
              lineStyle: {
                color: 'source',
                curveness: 0.1
              },
              emphasis: {
                focus: 'adjacency'
              }
            }
          ],
          color: ['#448BFF']
          // color: ['#749f83', '#c4ccd3', '#c23531', '#61a0a8', '#d48265', '#91c7ae', '#ca8622', '#bda29a', '#2f4554', '#6e7074', '#546570']
        }
        this.$nextTick(() => {
          if (this.chart === null) {
            this.chart = this.$echarts.init(this.$refs.chart)
          }
          this.chart.setOption(this.chartOption, true)
        })
      })
    },
    handleNode(list, parent) {
      let res = list.map(item => {
        if (item.hasOwnProperty('children') && item.children !== null && item.children.length > 0) {
          this.handleNode(item.children, item)
        }
        return {
          category: this.activeKnowLedge.name,
          name: item.id.toString(),
          knowledgeName: item.name,
          symbolSize: 10
        }
      })
      let links = res.map(item => {
        return { source: item.name.toString(), target: parent.id.toString() }
      })
      this.chartNodes = this.chartNodes.concat(res)
      this.chartLinks = this.chartLinks.concat(links)
    },
    resize() {
      if (this.chart !== null) {
        this.chart.resize()
      }
    },
    resetTree() {
      this.chart.setOption(this.chartOption, true)
    }
  }
}
</script>

<style lang="less" scoped>
.knowledge-list {
  height: 100%;
  overflow: auto;
  .item {
    .flexStyle();
    height: 32px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #e8eaec;
    font-size: 15px;
    padding: 0 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    white-space: nowrap;
    &.checked {
      background: #448bff;
      color: #fff;
    }
  }
}
.chart {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
.reset-button {
  padding: 0 5px;
  height: 20px;
  border-radius: 3px;
  color: #b0b0b0;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  position: absolute;
  right: 5%;
  top: 30px;
  z-index: 10;
  cursor: pointer;
}
</style>
